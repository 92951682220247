import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import BackAppBar from '../components/BackAppBar';
import { deleteAccount } from '../services/userService';

const DeleteAccountPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await deleteAccount(password);
      // Clear local storage and Redux store
      localStorage.clear();
      dispatch({ type: 'RESET_STORE' });
      navigate('/');
    } catch (err) {
      setError(err.message || 'Failed to delete account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user._id) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Delete Account - Social Wordle</title>
        <meta name="description" content="Delete your Social Wordle account" />
      </Helmet>
      <BackAppBar title="Delete Account" />
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Delete Your Account
          </Typography>
          
          <Alert severity="warning" sx={{ mb: 3 }}>
            This action cannot be undone. All your data, including game history, social connections, and profile information will be permanently deleted.
          </Alert>

          {!showConfirmation ? (
            <>
              <Typography variant="body1" paragraph>
                Before proceeding, please note:
              </Typography>
              <Box sx={{ maxWidth: 'fit-content', textAlign: 'left' }}>
                <ul style={{ margin: 0, paddingLeft: '20px' }}>
                  <li>Your account will be permanently deleted</li>
                  <li>All your game history will be removed</li>
                  <li>This action cannot be reversed</li>
                </ul>
              </Box>
              <Button
                variant="contained"
                color="error"
                onClick={() => setShowConfirmation(true)}
                sx={{ mt: 2 }}
              >
                I understand, proceed to delete account
              </Button>
            </>
          ) : (
            <form onSubmit={handleDeleteAccount}>
              <Typography variant="body1" paragraph>
                Please enter your password to confirm account deletion:
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                required
                sx={{ mb: 2 }}
              />
              
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => setShowConfirmation(false)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="submit"
                  disabled={isLoading || !password}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Delete Account'}
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default DeleteAccountPage; 