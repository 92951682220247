import { fetchWithAuth } from '../utils/apiUtils';

export const deleteAccount = async (password) => {
  try {
    const response = await fetchWithAuth('/user/delete-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to delete account');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || 'Failed to delete account');
  }
}; 