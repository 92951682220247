import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import FeedPage from './pages/FeedPage';
import BattleGame from './components/BattleGame';
import Wordle from './pages/WordlePage';
import Feed from './pages/FeedPage';
import Profile from './pages/ProfilePage';
import ProfilePageExternalView from './pages/ProfilePageExternalView';
import AuthCallback from './components/AuthCallback';
import ScrollToTop from './components/ScrollToTop';
import PostDetail from './components/PostDetail';
import BackAppBar from './components/BackAppBar';
import LeaderboardPage from './pages/LeaderboardPage';
import useViewportHeight from './hooks/useViewportHeight';
import WordleOverlayComponent from './components/WordleOverlayComponent';
import { saveUser, setToken, setUserGroups } from './reducers/user';
import { backendDomain } from './constants';
import { requestNotificationPermission, subscribeUser } from './utils/notificationUtils';
import { generateWordle } from './utils/apiUtils';
import NotificationsPage from './pages/NotificationsPage';
import { getUserGroups } from './utils/groupUtils';
import GroupInvitePage from './pages/GroupInvitePage';
import { HelmetProvider } from 'react-helmet-async';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import DeleteAccountPage from './pages/DeleteAccountPage';



function App() {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [solution, setSolution] = useState(null);
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState("");
  const [existingGuesses, setExistingGuesses] = useState([]);
  const [formattedGuesses, setFormattedGuesses] = useState([]);
  const [gameId, setGameId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loggedIn, setLoggedIn] = useState(null);
  const [isFetchingWordle, setIsFetchingWordle] = useState(false);

 // const [shouldRedirect, setShouldRedirect] = useState(false);

  useViewportHeight();

  useEffect(() => {
    const initializeUser = async () => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        setLoggedIn(true);
        dispatch(saveUser(storedUser));

        // Check if the token is available
        if (storedUser.token) {
          try {
            const groups = await getUserGroups(storedUser.token);
            dispatch(setUserGroups(groups));
          } catch (error) {
            console.error('Error fetching groups:', error);
          }
        } else {
          console.error('No token found for the user.');
        }
      } else {
        setLoggedIn(false);
        dispatch(saveUser(null));
      }
    };

    initializeUser();
  }, [dispatch]);

  const fetchWordle = async () => {
    if (isFetchingWordle){
      console.log("App: Fetching wordle is already in progress");
      return; // Prevent concurrent calls
    } 
    
    try {
      setIsFetchingWordle(true);
      const json = await generateWordle(user._id);
      if (json.wordle) {
        const { solution, guesses, formattedGuesses, _id, message, result } = json.wordle;
        setSolution(solution);
        setResult(result);
        setExistingGuesses(Array.isArray(guesses) ? guesses : []);
        setFormattedGuesses(Array.isArray(formattedGuesses) ? formattedGuesses : []);
        setGameId(_id);
        setMessage(message);
      }
    } catch (err) {
      console.error('Error fetching Wordle:', err);
    } finally {
      setIsFetchingWordle(false);
    }
  };

  useEffect(() => {
    if (user.status === 'loading') return;
    if (user.status === 'failed') {
      console.error('Error loading user:', user.error);
      return;
    }

    if (user._id && (location.pathname === '/wordle' || location.pathname === '/')) {
      setLoggedIn(true);
      console.log("App: Fetching wordle as user is logged in and path is", location.pathname);
      fetchWordle();
    } else {
      console.log('App: User not logged in. No wordle');
    }
  }, [user._id, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/wordle' && user._id) {
      console.log("App: Fetching wordle as path is /wordle");
      fetchWordle();
    }
  }, [location.pathname, user._id]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
        dispatch(setToken(token));
    }
  }, [dispatch]);

  //console.log("Redirect logic variables: Solution", solution, "loggedIn", loggedIn, "userid", user._id, "result", result);
  // Only redirect on initial load and when at root path
  const shouldRedirect =  location.pathname === '/' && ( loggedIn === false || (loggedIn && result === "in_progress"));

  //console.log("Final redirect", shouldRedirect);

  return (
    <>
      {shouldRedirect && <Navigate to="/wordle" replace />}
      <ScrollToTop />
      <LocationHandler />
      <Routes>
        <Route path="/" element={<Feed groupId="global" />} />
        <Route path="/feed" element={<Feed groupId="global" />} />
        <Route path="/group/:inviteCode" element={<GroupInvitePage />} />
        <Route path="/wordle" element={
          <Wordle 
            solution={solution}
            existingGuesses={existingGuesses}
            formattedGuesses={formattedGuesses}
            gameId={gameId}
            user={user}
            message={message}
          />
        } />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/profile/:id" exact element={<ProfilePageExternalView />} />
        <Route path="/post/:postId" element={<PostDetail />} />
        <Route path="/battle/:battleId" element={<BattleGame />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/deleteaccount" element={<DeleteAccountPage />} />
      </Routes>
    </>
  );
}

function LocationHandler() {
  const location = useLocation();
  const isPostDetailPage = location.pathname.startsWith('/post/');
  const isBattlePage = location.pathname.startsWith('/battle/');

  return (isPostDetailPage) ? <BackAppBar /> : null;
}

export default App;
