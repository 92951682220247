import React from 'react';
import { Container, Typography, Paper, Box, Link } from '@mui/material';
import BackAppBar from '../components/BackAppBar';
import './css/PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
    return (
        <>
            <BackAppBar />
            <Container maxWidth="md" className="privacy-policy-container">
                <Paper elevation={0} className="privacy-policy-paper">
                    <Box sx={{ p: 3, textAlign: 'left' }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'left' }}>
                            SOCIAL WORDLE - PRIVACY POLICY
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" paragraph sx={{ textAlign: 'left' }}>
                            Last updated: April 2025
                        </Typography>
                        
                        <Box sx={{ mt: 4, textAlign: 'left' }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
                                YOUR PRIVACY IS IMPORTANT TO US
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                Your privacy is important to Social Wordle ("we" or "us"). This Privacy Policy explains how we collect, process, use, share, and protect information about you.
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                This Privacy Policy applies to our online and mobile services, including our Social Wordle application ("App"), and any websites operated by us (collectively, the "Services"). By using the Services, you agree to the terms of this Privacy Policy.
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                If you have questions about this Privacy Policy, please contact us at{' '}
                                <Link href="mailto:socialwordle@gmail.com">socialwordle@gmail.com</Link>.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                INFORMATION WE COLLECT
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left' }}>
                                Information You Provide:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 2, textAlign: 'left' }}>
                                <Typography component="li">Name</Typography>
                                <Typography component="li">Email Address (Google OAuth)</Typography>
                                <Typography component="li">Profile information (e.g., game statistics, user-generated content)</Typography>
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, textAlign: 'left' }}>
                                Information We Collect Automatically:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 2, textAlign: 'left' }}>
                                <Typography component="li">Device type and model</Typography>
                                <Typography component="li">OS version</Typography>
                                <Typography component="li">Country</Typography>
                                <Typography component="li">App version</Typography>
                                <Typography component="li">IP Address</Typography>
                                <Typography component="li">Usage information such as interaction with the app, time spent, and game performance.</Typography>
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                HOW WE USE YOUR INFORMATION
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                We use your information to:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 2, textAlign: 'left' }}>
                                <Typography component="li">Provide and enhance our Services;</Typography>
                                <Typography component="li">Manage user authentication and profiles;</Typography>
                                <Typography component="li">Display your gameplay results to other users in the social feed;</Typography>
                                <Typography component="li">Operate and improve the performance of the App;</Typography>
                                <Typography component="li">Communicate with you about your account or service-related updates;</Typography>
                                <Typography component="li">Monitor and analyze usage patterns for internal analytics;</Typography>
                                <Typography component="li">Protect the security and integrity of the Services.</Typography>
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                Currently, Social Wordle does not display advertisements or share your information for targeted advertising.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                DATA SHARING AND DISCLOSURE
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                We do not sell, rent, or trade your personal information. We share your information only under the following circumstances:
                            </Typography>
                            <Typography component="ul" sx={{ pl: 2, textAlign: 'left' }}>
                                <Typography component="li"><strong>With Other Users:</strong> Your gameplay results, profile information, and interactions (such as likes and comments) are visible to other users in the app.</Typography>
                                <Typography component="li"><strong>Service Providers:</strong> We may employ third-party companies and individuals (such as Firebase by Google for analytics) strictly to facilitate and improve our services.</Typography>
                                <Typography component="li"><strong>Legal and Compliance:</strong> We may disclose your information if required by law or to protect our rights, safety, or the safety of others.</Typography>
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                CONTROL OVER YOUR INFORMATION
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                You can request to access, correct, or delete your personal information by contacting us at{' '}
                                <Link href="mailto:socialwordle@gmail.com">socialwordle@gmail.com</Link>. We will respond within a reasonable timeframe.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                DATA RETENTION
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                We retain your personal information for as long as your account is active. Upon account deletion, we will remove your personal data within a reasonable time.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                SECURITY
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                We use industry-standard security measures, including encryption, to protect your information. However, no internet transmission or storage system is completely secure, so we cannot guarantee absolute security.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                CHILDREN'S PRIVACY
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                Social Wordle is not intended for children under the age of 13. We do not knowingly collect information from children under 13. If you believe we have collected such information, please contact us at{' '}
                                <Link href="mailto:socialwordle@gmail.com">socialwordle@gmail.com</Link>, and we will delete the data immediately.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                THIRD-PARTY LINKS
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                Our Services may include links to third-party services or features, including social media platforms. We are not responsible for the privacy practices of these third-party services. Please review their privacy policies before interacting with these platforms.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                CHANGES TO THIS POLICY
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                We may update this Privacy Policy periodically. Any changes will be posted on this page, and we encourage you to review this policy regularly.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: 'left' }}>
                                CONTACT US
                            </Typography>
                            <Typography paragraph sx={{ textAlign: 'left' }}>
                                For any privacy-related questions or escalations, please contact us at:{' '}
                                <Link href="mailto:socialwordle@gmail.com">socialwordle@gmail.com</Link>
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default PrivacyPolicyPage; 